.vertical-dots
{
    right:10px;
    list-style: none;
    display: block;
    position: absolute;
    top: 40%;
    margin-top: -10px;
    text-align: right;
}
.vertical-dots li
{
    position: relative;
    width: 20px;
    height: 20px;
    cursor: pointer;
}
.vertical-dots li button
{
    font-size: 0;
    line-height: 0;
    display: block;
    width: 20px;
    height: 20px;
    padding: 5px;
    cursor: pointer;
    color: transparent;
    border: 0;
    outline: none;
    background: transparent;
}
.vertical-dots li button:hover,
.vertical-dots li button:focus
{
    outline: none;
}
.vertical-dots li button:hover:before,
.vertical-dots li button:focus:before
{
    opacity: 1;
}
.vertical-dots li button:before
{
    font-family: 'slick';
    font-size: 0.8vw;
    line-height: 20px;

    position: absolute;
    top: 0;
    left: 0;

    width: -3vw;
    height: 20px;

    content: '•';
    text-align: center;

    opacity: .25;
    color: whitesmoke;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.vertical-dots li.slick-active button:before
{
    opacity: .75;
    color: white;
}

.slider
{
  margin-left: -20px;
}
  
.react-player {
    position: absolute;
    overflow: hidden;
    top: 51.3vw;
    left: 50%;
    border-radius: 1vw;
    z-index: 10;
    transform:translate(-50%, -50%);
}

@media screen and (max-width: 760px) {
    .react-player {
        top: 87vw;
        border-radius: 1vw;
    }
}    