.Menu {
    position: fixed;
    display: grid;
    place-items: center;
    width: 100vw;
    height: 104vh;
    left: -16px;
    top: 0;
    z-index: 20;
    float: left;
    background: #5BB6FC;
    transform: translate(-100%);
    transition: all .45s;
    font-family: 'Poppins', 'Prompt', sans-serif;
    font-weight: 600;
}

@media screen and (max-device-width: 760px) {
    .Menu {
        left: -16px;
    }
}    

.Menu.open {
    transform: translate(0);
    z-index: 20;
}

.Menu > .btn-close {
    font-size: 6vw;
    position: absolute;
    left: 15px;
    top: 15px;
    cursor: pointer;
    color: #ffffff;
}

.Menu > .Menu-items {
    display: grid;
    place-items: center;
    grid-gap: 10px;
    margin-top: -30%;
}

.Menu > .Menu-items > a {
    color: #ffffff;
    font-size: 9vw;
    text-decoration: none;
    position: relative;
    cursor: pointer;

}


.Menu > .Menu-items > a::after {
    content: "";
    width: 100%;
    height: 2vw;
    position: absolute;
    left: 0;
    bottom: 0;
    background: #ffffff;
    transform: scaleX(0);
    transition: all .45s;
}

